import React from 'react';
//styles
import styles from './open-positions.module.css';
//icons
import Arrow from '../../../images/career/arrow.inline.svg';
import MapIcon from '../../../images/career/map-icon.inline.svg';
import TechIcon from '../../../images/career/tech-icon.inline.svg';
//hooks
import useModal from '../../../hooks/useModal';
//components
import PositionView from '../position-view';

interface ITechnology {
  id: string;
  technology_name: string;
}

export type TPosition = {
  id: string;
  position_title: string;
  city?: string;
  remote?: boolean;
  higher_education?: string;
  position_overview?: string;
  project_overview?: string;
  requirements?: string;
  what_we_offer?: string;
  nice_to_have?: string;
  responsibilities?: string;
  salary?: {
    currency: 'USD' | 'UAH' | 'EUR';
    min: string | number;
    max: string | number;
  };
  stack_of_technologies?: ITechnology[];
};

interface IProps {
  positions: TPosition[];
}

function OpenPositions({ positions }: IProps) {
  const { handleModal } = useModal();

  return (
    <section className={styles.positionContainer} id="current-open-positions">
      <div className="inner">
        <h2 className={styles.positionCapture}>Current open positions</h2>
        <ul className={styles.cardsList}>
          {positions?.map((position) => {
            const { id, position_title, position_overview, city, remote, stack_of_technologies } =
              position;
            return (
              <li
                key={id}
                className={styles.cardContainer}
                onClick={() => handleModal(<PositionView positionDetails={position} />, false)}
              >
                <div className={styles.cardDescription} id={id}>
                  <p className={styles.positionTitle}>{position_title}</p>
                  {position_overview ? (
                    <p className={styles.positionName}>{position_overview}</p>
                  ) : (
                    <p className={styles.positionName}>{position_title}</p>
                  )}
                  <div className={styles.extraInfoContainer}>
                    {stack_of_technologies && stack_of_technologies?.length > 0 && (
                      <div className={styles.extraInfo}>
                        <div className={styles.techStack}>
                          <TechIcon className={styles.iconTech} />
                          <ul className={styles.techList}>
                            {stack_of_technologies.map(({ id, technology_name }) => (
                              <li key={id} className={styles.techItem}>
                                {technology_name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className={styles.extraInfo}>
                      {/* {!!remote && (
                    <>
                      <MapIcon /> <span className={styles.extra}>Remote</span>
                    </>
                  )} */}
                      {(!!city || !!remote) && <MapIcon />}
                      {!!city && !!remote ? (
                        <span className={styles.extra}>{city}, Remote</span>
                      ) : (
                        <>
                          {!!city && <span className={styles.extra}>{city}</span>}
                          {!!remote && <span className={styles.extra}>Remote</span>}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.details}>
                  <span className={styles.leanMore}>Learn more</span>
                  <Arrow className={styles.detailsBtn} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default OpenPositions;
