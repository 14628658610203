import React from 'react';
import styles from './work-balance.module.css';

interface IWorkBalance {
  content: {
    image: React.ReactNode;
    title: string;
    description: string;
  }[];
}

const WorkBalance = ({ content }: IWorkBalance) => {
  return (
    <section className={styles.container}>
      <div className="inner">
        <h2 className={styles.caption}>Work and Life balance</h2>
        <ul className={styles.contentBlock}>
          {content.map(({ image, title, description }) => (
            <li key={title} className={styles.card}>
              {image}
              <p className={styles.title}>{title}</p>
              <p className={styles.description}>{description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default WorkBalance;
