import * as React from 'react';
import clsx from 'clsx';
//styles
import styles from './advantages.module.css';

function Advantages() {
  return (
    <section className={clsx('section', styles.section)}>
      <p className={styles.advanturesCapture}>
        Brocoders is a place where talented people grow working on great products.
      </p>
      <div className={styles.benefitsContainer}>
        <div className={styles.benefits}>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>87</dt>
            <dd className={styles.description}>top specialists</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>8</dt>
            <dd className={styles.description}>years of experience</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>85</dt>
            <dd className={styles.description}>projects done</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>60%</dt>
            <dd className={styles.description}>senior engineers</dd>
          </dl>
        </div>
        <div className={styles.desctiptionAdvantages}>
          <p className={styles.aboutUs}>
            Brocoders is a technical partner for building web-based and mobile app products. Should
            they be scaled-up or developed from scratch - we will manage with it.
          </p>
          <p className={styles.aboutUs}>
            Besides programming, our service includes user experience, graphic design and project
            management consultancies.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Advantages;
