import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import clsx from 'clsx';
import { meta } from '../../metaData';
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styles from './career.module.css';
//components
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Header from './header';
import Advantages from './advantages';
import OurValues from './our-values';
import OurProjects from './our-projects';
import Video from 'components/layout/video';
import OpenPositions from './open-positions';
import WorkBalance from './work-balance';
import InstagramFeed from './instagram-feed';
import RecruitmentProcess from './recruitment';
import JoinUs from './join-us';
//data
import workBalanceProps from './workBalance';
//types
import { TPosition } from './open-positions';

interface IPositionProps {
  pathContext: {
    allPositions: TPosition[];
  };
}

function Career({ pathContext }: IPositionProps) {
  const data = useStaticQuery(graphql`
    query {
      staff: file(relativePath: { eq: "career/staff.png" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      working: file(relativePath: { eq: "career/working-process.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      discussing: file(relativePath: { eq: "career/discussing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      discussing1: file(relativePath: { eq: "career/discussing1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hands: file(relativePath: { eq: "career/hands.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const captchaRef = useRef<ReCAPTCHA>(null);

  return (
    <Layout title="Career" navBtnTextColor="#D91965" metaData={{ main: meta.career }}>
      <Header capture="Develop Your Career along with Brocoders">
        <p className="header-text">We are not just yet another outsourcing company.</p>
        <p className="header-text">
          Brocoders is a community of talents who create forward-thinking projects together.
        </p>
        <a className="btn btn_60 btn__white" href="#current-open-positions">
          Explore roles
        </a>
      </Header>
      <main className={clsx('main', styles.main)}>
        <Advantages />
        <OurValues />
        <section className={styles.poster}>
          <div
            className={styles.thumb}
            style={
              {
                '--mobile-img': `url(${data.hands.childImageSharp.fluid.srcWebp})`,
                '--desktop-img': `url(${data.staff.childImageSharp.fluid.srcWebp})`,
                '--tab-img': `url(${data.working.childImageSharp.fluid.srcWebp})`,
              } as React.CSSProperties
            }
          ></div>
        </section>
        <OurProjects title="You will likely be working on similar projects:" />
        <OpenPositions positions={pathContext.allPositions} />
        <Video
          poster={data.discussing.childImageSharp.fluid.srcWebp}
          posterWithPlayBtn={data.discussing1.childImageSharp.fluid.srcWebp}
          video="Bro_Office_2"
          videoStyles={styles.video}
        />
        <WorkBalance content={workBalanceProps} />
        <InstagramFeed />
        <RecruitmentProcess />
        <JoinUs positions={pathContext.allPositions} recaptchaRef={captchaRef} />
      </main>
      <Footer />
    </Layout>
  );
}

export default Career;
