import React from 'react';
//icons
import Learning from '../../images/career/learning.inline.svg';
import Innovative from '../../images/career/innovative.inline.svg';
import Healthy from '../../images/career/healthy.inline.svg';
import Efficient from '../../images/career/efficient.inline.svg';
import Environment from '../../images/career/environment.inline.svg';
import Insurance from '../../images/career/insurance.inline.svg';

export default [
  {
    image: <Learning />,
    title: 'Constant learning',
    description: 'Free English classes, seminars and workshops, IT conferences',
  },
  {
    image: <Innovative />,
    title: 'Innovative tech stack',
    description:
      'We follow best practices and utilise the latest technologies for product development',
  },
  {
    image: <Healthy />,
    title: 'Healthy lifestyle',
    description: 'Outdoor activities and travels, sports, fitness, medical consultations',
  },
  {
    image: <Efficient />,
    title: 'Efficient teamwork',
    description: 'We use Agile, Scrum and other best practices in product management',
  },
  {
    image: <Environment />,
    title: 'Inspiring environment',
    description: 'Desktop games, books, free launches, snakes and coffee machine',
  },
  {
    image: <Insurance />,
    title: 'Social insurance',
    description: 'Paid annual and sick leave, in-house accounting service',
  },
];
