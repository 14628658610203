import * as React from 'react';
import clsx from 'clsx';
import styles from './header.module.css';
import Wave from 'pages/components/header/wave';

interface IHeaderProps {
  capture: string;
  description?: string;
  children?: React.ReactNode;
}

function Header({ capture, children = {}, description = '' }: IHeaderProps) {
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        <div className="inner">
          <div className={styles.content}>
            <h1 className={clsx('header-title', styles.title)}>{capture}</h1>
            <p className="header-text">{description}</p>
            {children}
          </div>
        </div>
        <Wave color="#ffffff" opacity={0.12} />
      </div>
    </header>
  );
}

export default Header;
