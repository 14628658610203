import React from 'react';
import clsx from 'clsx';
//styles
import styles from './our-values.module.css';
//images
import Care from '../../../images/career/care.inline.svg';
import Honesty from '../../../images/career/honesty.inline.svg';
import Excellence from '../../../images/career/excellence.inline.svg';
import Authentic from '../../../images/career/authentic.inline.svg';

function OurValues() {
  return (
    <section className={clsx('section', styles.section)}>
      <p className={styles.motto}>Our Values</p>
      <div className={styles.images}>
        <Care className={styles.img} />
        <Honesty className={styles.img} />
        <Excellence className={styles.img} />
        <Authentic className={styles.img} />
      </div>
    </section>
  );
}

export default OurValues;
