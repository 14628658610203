import React from 'react';
import styles from './join-us.module.css';
import loadable from '@loadable/component';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
// icons
import Arrow from '../../../images/career/hollow-arrow.inline.svg';
//types
import { TPosition } from '../open-positions';

interface IPositionProps {
  positions: TPosition[];
  recaptchaRef: any;
}

const ApplicationForm = loadable(() => import('../application-form'));

const JoinUs = ({ positions, recaptchaRef }: IPositionProps) => {
  const breakpoint = useBreakpoint();

  return (
    <section className={styles.joinContainer}>
      <div className="inner">
        <p className={styles.caption}>
          We’re always looking for great talent and ambitious people{' '}
          {!breakpoint.s && <Arrow className={styles.arrow} />}
        </p>
        <p className={styles.letWork}>Let’s get to work together</p>
        <a className={styles.mail} href="mailto:join@brocoders.team">
          join@brocoders.team
        </a>
        <p className={styles.invitation}>
          We’re always looking to connect with individuals who want to make the best work of their
          lives with the interesting brands. If you’re interested in working with us or learning
          more, drop us a note, portfolio link, or resume.
        </p>
        <ApplicationForm positions={positions} refForRecaptcha={recaptchaRef} />
      </div>
    </section>
  );
};
export default JoinUs;
