import React, { FC, memo } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import loadable from '@loadable/component';
//styles
import styles from './recruitment.module.css';
//components
import SlideRecruitment from './slide-recruitment';
//icons
import call from '../../../images/career/call.svg';
import followUp from '../../../images/career/follow-up.svg';
import interview from '../../../images/career/interview.svg';
import offer from '../../../images/career/offer.svg';
import review from '../../../images/career/review.svg';

interface IRecruitmentProps {
  definitions?: {
    description: string;
    path: string;
  }[];
}

export const defaultProps = [
  {
    path: review,
    description: 'Job application review',
  },
  {
    path: call,
    description: 'Call with talent manager',
  },
  {
    path: interview,
    description: 'Interview with technical expert',
  },
  {
    path: followUp,
    description: 'Follow-up',
  },
  {
    path: offer,
    description: 'Job offer',
  },
];

const SwiperSlider = loadable(() => import('components/swiper-slider'));

const RecruitmentProcess: FC<IRecruitmentProps> = ({ definitions = defaultProps }) => {
  const breakpoint = useBreakpoint();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Recruitment process</h2>
      {breakpoint.s ? (
        <SwiperSlider
          navigationEnabled={false}
          autoPlay={true}
          slideClassName={styles.slide}
          paginationColor="#d91965"
          paginationInactiveColor="#656c7b"
          activeBulletClass={styles.activeBullet}
        >
          {definitions.map(({ path, description }) => (
            <SlideRecruitment image={path} description={description} key={description} />
          ))}
        </SwiperSlider>
      ) : (
        <ul className={styles.list}>
          {definitions.map(({ path, description }) => (
            <li key={description} className={styles.item}>
              <div className={styles.content}>
                <img src={path} className={styles.image} alt="" />
                <p className={styles.description}>{description}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(RecruitmentProcess);
