import React, { useContext, useEffect, useRef } from 'react';
import loadable from '@loadable/component';
//styles
import styles from './position-view.module.css';
//components
import JobOverView from '../job-overview';
// import ApplicationForm from '../application-form';
// context
import { ModalContext } from '../../modal';
//types
import { TPosition } from '../open-positions';
//icons
import CloseBtn from '../../../images/career/close-form.inline.svg';
//assets
const { setPositionId } = require('../setPositionId');

interface IPosition {
  positionDetails: TPosition;
  capchaRef?: any;
}

const ApplicationForm = loadable(() => import('../application-form'));

const PositionView = ({ positionDetails, capchaRef }: IPosition) => {
  const { id, position_title } = positionDetails;

  const { closeModal } = useContext(ModalContext);

  const applyContainer = useRef<null | HTMLDivElement>(null);

  const onOverlayClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (evt.currentTarget === evt.target) {
      closeModal();
    }
  };

  const positionId = setPositionId(id, position_title);

  useEffect(() => {
    window.history.pushState({}, 'My new page title', `/careers/${positionId}/`);
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    const scrollTop = (e: Event) => {
      document.body.scrollTop;
    };
    window.addEventListener('popstate', scrollTop);

    return () => {
      window.history.back();
      window.removeEventListener('popstate', scrollTop);
    };
  }, []);

  return (
    <div className={styles.viewPort} onClick={onOverlayClick}>
      <div className={styles.viewContainer}>
        <div className={styles.overview}>
          <JobOverView
            positionOverview={positionDetails}
            isModal={true}
            onApplyClick={() => {
              if (applyContainer.current) {
                applyContainer.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
              }
            }}
          />

          <div className={styles.closeBtn} onClick={closeModal}>
            <CloseBtn />
          </div>
        </div>
        <div className={styles.form} ref={applyContainer}>
          <div className={styles.formHead} id="join-now">
            <p className={styles.captureInfo}>{position_title}</p>
            <span className={styles.letWork}>Let’s get to work together</span>

            <a className={styles.mail} href="mailto:join@brocoders.team">
              join@brocoders.team
            </a>
          </div>
          <ApplicationForm
            positions={[positionDetails]}
            invisibleRecaptcha={false}
            refForRecaptcha={capchaRef}
          />
        </div>
      </div>
    </div>
  );
};

export default PositionView;
