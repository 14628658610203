import React from 'react';
// styles
import styles from './slide-recruitment.module.css';

interface ISlideRecruitment {
  image: string;
  description: string;
}

function SlideRecruitment({ image, description }: ISlideRecruitment) {
  return (
    <li className={styles.content} key={description}>
      <img src={image} className={styles.image} alt="" />
      <p className={styles.description}>{description}</p>
    </li>
  );
}

export default SlideRecruitment;
